import { withI18nPlaceholders } from '@change-corgi/core/react/i18n';

import I18N_PLACEHOLDERS from 'config/i18n/placeholders/mobileAppDownload/bottom-banner.yml';

import { Banner } from '../component/Banner';

type AppDownloadBottomBannerProps = {
	appDownloadBottomBannerEnabled: {
		traffic_percentage: number;
	};
	ownPetition: boolean;
	sourceLocation: string;
	starterUserId: string;
};

export const AppDownloadBottomBanner = withI18nPlaceholders(
	I18N_PLACEHOLDERS,
	function AppDownloadBottomBannerEntry({
		appDownloadBottomBannerEnabled,
		ownPetition,
		sourceLocation,
		starterUserId,
	}: AppDownloadBottomBannerProps): React.JSX.Element | null {
		if (!ownPetition) return null;
		if (appDownloadBottomBannerEnabled.traffic_percentage <= parseInt(starterUserId, 10) % 100) return null;

		return <Banner sourceLocation={sourceLocation} />;
	},
);
